import Datei from './Datei'
import Kunde from './Kunde'
import Produkt from './Produkt'
import dateFormat from 'date-format'

class Auftrag {
  id
  auftragsnummer
  produkt
  kunde
  zaehlernummer
  verbrauch
  lieferbeginn
  wechselgrund
  vorversorger
  vorversorgerKundennummer
  vorversorgerKuendigungsdatum
  unterschriftsdatum
  unterschriftsort
  tarifId
  erstelldatum
  isAuftragDateistatusOk
  isFairberatenDateistatusOk
  dateien
  status
  produkttyp
  kundentyp
  /**
   * @param {{ id:Number, auftragsnummer:String, produkt:{ produktId:String, tarifId:String, name:String, produkttyp:String, kundentyp:String },
   * kunde:{ email:?String, geburtsdatum:?Date, lieferadresse:Adresse, rechnungsadresse:?Adresse, telefonnummer:Kontaktnummer,
   * faxnummer:?Kontaktnummer, bankdaten:?Bankdaten, promotionErlaubt:Boolean, promotionHighlightsErlaubt:Boolean
   * firmenname:?String, registergericht:?String, handelsregisternummer:?String, branche:?String }, zaehlernummer:String,
   * verbrauch:Number, lieferbeginn:?Date, wechselgrund:String, vorversorger:?String, vorversorgerKundennummer:?String,
   * vorversorgerKuendigungsdatum:?Date, unterschriftsdatum:Date, unterschriftsort:String,
   * erstelldatum:Date, dateien:[{inhalt:String, dateityp:String, mediaTypeName:String, status:String}] }} data
   */
  constructor(data) {
    this.id = data.id
    this.auftragsnummer = data.auftragsnummer
    this.tarifId = typeof data.produkt === 'object' ? data.produkt.tarifId : data.tarifId
    this.produkt = typeof data.produkt === 'object' ? new Produkt(data.produkt) : data.produkt
    this.kunde = new Kunde(data.kunde)
    this.zaehlernummer = data.zaehlernummer
    this.verbrauch = data.verbrauch
    this.lieferbeginn = data.lieferbeginn
    this.wechselgrund = data.wechselgrund
    this.vorversorger = data.vorversorger
    this.vorversorgerKundennummer = data.vorversorgerKundennummer
    this.vorversorgerKuendigungsdatum = data.vorversorgerKuendigungsdatum
    this.unterschriftsdatum = data.unterschriftsdatum instanceof Date ? data.unterschriftsdatum : new Date(data.unterschriftsdatum)
    this.unterschriftsort = data.unterschriftsort
    this.erstelldatum = data.erstelldatum instanceof Date ? data.erstelldatum : new Date(data.erstelldatum)
    this.auftragDateiStatus = data.auftragDateiStatus
    this.fairberatenDateiStatus = data.fairberatenDateiStatus
    this.dateien = data.dateien ? data.dateien.map(d => new Datei(d)) : null
    this.status = data.status
    this.kundentyp = data.kundentyp
    this.produkttyp = data.produkttyp
    this.widerrufsbelehrungZurKenntnis = data.widerruf
  }

  toFormData() {
    let auftrag = JSON.parse(JSON.stringify(this))

    auftrag.unterschriftsdatum = auftrag.unterschriftsdatum ? dateFormat('yyyy-MM-dd', new Date(auftrag.unterschriftsdatum)) : null
    auftrag.erstelldatum = auftrag.erstelldatum ? dateFormat('yyyy-MM-dd', new Date(auftrag.erstelldatum)) : null
    auftrag.kunde.geburtsdatum = auftrag.kunde.geburtsdatum ? dateFormat('yyyy-MM-dd', new Date(auftrag.kunde.geburtsdatum)) : null

    return auftrag
  }
}
export default Auftrag
