<template>
  <div class="auftragsdateien">
    <b-row>
      <b-col cols="12" order-lg="4" order-md="1">
        <b-alert show>
          <b-icon-alert-circle scale="3" class="mt-3 mb-2 ml-2 mr-4 float-left"></b-icon-alert-circle>
          <p class="pl-5">
          Achte bei Fotos mit dem Smartphone/Tablet darauf, die Dokumente so groß wie möglich, aber nicht abgeschnitten, aufzunehmen.
          Die Fotos müssen scharf und gut beleuchtet sein. Nimm die Fotos möglichst im Hochformat auf.
          </p>
        </b-alert>
      </b-col>
      <b-col lg="4" md="12" order-lg="1" order-md="2">
        <b-form-group
          description="Auftragsformular"
        >
          <ValidationProvider v-slot="validationContext" rules="required|ext:jpg,jpeg,pdf" name="Auftragsformular" vid="dateien.auftrag">
            <b-form-file
              v-model="form.dateien.auftrag"
              :state="$getValidationState(validationContext)"
              placeholder="Auftragsscan oder -fotos auswählen"
              drop-placeholder="Auftragsscan oder -fotos hier ablegen"
              multiple
              accept=".jpg, .jpeg, .pdf"
              :file-name-formatter="formatNames"
              :size="inputSize"
              v-on:change="$emit('change', form)"
              v-on:input="onAuftragInput"
            ></b-form-file>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <ul v-if="form.dateien.auftrag.length > 1">
          <li v-for="(file, index) in form.dateien.auftrag" :key="index">
            {{ file.name }}
          </li>
        </ul>
      </b-col>
      <b-col lg="4" md="12" order-lg="2" order-md="3">
        <b-form-group
          description="Fair Beraten"
        >
          <ValidationProvider v-slot="validationContext" rules="required|ext:jpg,jpeg,pdf" name="Fairberaten" vid="dateien.fairberaten">
            <b-form-file
              v-model="form.dateien.fairberaten"
              :state="$getValidationState(validationContext)"
              placeholder="Fair-Beraten-Scan oder -Foto auswählen"
              drop-placeholder="Fair-Beraten-Scan oder -Foto hier ablegen"
              accept=".jpg, .jpeg, .pdf"
              :file-name-formatter="formatNames"
              :size="inputSize"
              v-on:change="$emit('change', form)"
              v-on:input="onFairberatenInput"
            ></b-form-file>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="12" order-lg="3" order-md="4">
        <b-form-group
          description="Sonstiges"
        >
          <ValidationProvider v-slot="validationContext" rules="ext:jpg,jpeg,pdf" name="Sonstiges" vid="dateien.sonstiges">
            <b-form-file
              v-model="form.dateien.sonstiges"
              :state="$getValidationState(validationContext)"
              placeholder="Scan oder Fotos auswählen"
              drop-placeholder="Scan oder Fotos hier ablegen"
              multiple
              accept=".jpg, .jpeg, .pdf"
              :file-name-formatter="formatNames"
              :size="inputSize"
              v-on:change="$emit('change', form)"
              v-on:input="onSonstigesInput"
            ></b-form-file>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <ul v-if="form.dateien.sonstiges.length > 1">
          <li v-for="(file, index) in form.dateien.sonstiges" :key="index">
            {{ file.name }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {EmptyFormData as DefaultFormData, Dateityp} from '../constants'
  import { FormFilePlugin, AlertPlugin, BIconAlertCircle } from 'bootstrap-vue'
  Vue.use(FormFilePlugin)
  Vue.use(AlertPlugin)

  export default {
    name: 'Auftragsdateien',
    model: {
      prop: 'form',
      event: 'change'
    },
    components: {
      BIconAlertCircle
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    methods: {
      formatNames(files) {
        if (files.length === 1) {
          return files[0].name
        } else {
          return `${files.length} Dateien ausgewählt`
        }
      },
      loadFiles(dateityp, files) {
        this.$emit('file-upload-reset', dateityp)
        files.forEach(f => {
          let reader = new FileReader()
          reader.onload = (event) => {
            this.$emit('file-upload-done', {dateityp: dateityp, content: btoa(event.target.result)})
          }
          reader.onabort = () => {
            this.$emit('file-upload-failed')
          }
          reader.onerror = () => {
            this.$emit('file-upload-failed')
          }
          this.$emit('file-upload-started')
          reader.readAsBinaryString(f);
        })
      },
      onAuftragInput(files) {
        this.loadFiles(Dateityp.Auftrag, files)
      },
      onFairberatenInput(file) {
        this.loadFiles(Dateityp.Fairberaten, [file])
      },
      onSonstigesInput(files) {
        this.loadFiles(Dateityp.Sonstiges, files)
      }
    }
  }
</script>

<style scoped>

</style>
