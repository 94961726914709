import {DateiStatuus} from '../../constants'

class Datei {
  /** @param {{ id:Integer, dateityp:String, mediaTypeName:String, status:String }} data */
  constructor(data) {
    this.id = data.id
    this.dateityp = data.dateityp
    this.mediaTypeName = data.mediaTypeName
    this.status = DateiStatuus[data.status]
  }
}
export default Datei
