<template>
  <b-row>
    <b-col class="abschnitt auftragvollmacht mb-2">
      <b-row>
        <b-col class="title mb-2">
          <div>
            <strong>Produkt- und Tarifinformationen</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Ich bin damit einverstanden, dass die Vattenfall Europe Sales GmbH
            mich über ihre aktuellen Strom- und Gasprodukte informiert.</p>
          <p><b-form-checkbox :value="1"
                              :disabled="disabled"
                              :size="inputSize" :unchecked-value="0" v-model="form.kunde.promotionErlaubtEmail" v-on:change="$emit('change', form)" inline>E-Mail</b-form-checkbox>
            <b-form-checkbox :value="1"
                             :disabled="disabled"
                             :size="inputSize" :unchecked-value="0" v-model="form.kunde.promotionErlaubtTelefonSms" v-on:change="$emit('change', form)" inline>Telefon/SMS</b-form-checkbox></p>
        </b-col>
        <b-col>
          <p>Ich bin damit einverstanden, dass die Vattenfall Europe Sales GmbH mir
            regelmäßig per E-Mail den aktuellen Newsletter zusendet. </p>
          <p><b-form-checkbox :value="1"
                              :disabled="disabled"
                              :size="inputSize" :unchecked-value="0" v-model="form.kunde.promotionNewsletterErlaubt" v-on:change="$emit('change', form)" inline>E-Mail</b-form-checkbox></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Die Unterschrift zu Produkt- und Tarifinformationen wird nicht gesondert digitalisiert.</p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  import Vue from 'vue'
  import {FormCheckboxPlugin, FormGroupPlugin, FormPlugin} from 'bootstrap-vue'
  import {EmptyFormData as DefaultFormData} from '../constants'

  Vue.use(FormPlugin)
  Vue.use(FormCheckboxPlugin)
  Vue.use(FormGroupPlugin)

  export default {
    name: 'ProduktUndTarifinformationen',
    model: {
      prop: 'form',
      event: 'change'
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    data() {
      return {
      }
    }
  }
</script>

<style scoped>

</style>
