import Iban from 'iban'

const IbanValidator = (value) => {
    // Returns a Boolean or a Promise that resolves to a boolean.
    if (Iban.isValid(value)) {
      return true;
    }

    return `{_field_} muss eine gültige IBAN sein.`
}

export default IbanValidator
