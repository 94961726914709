export default function ucfirst(text) {
  if (typeof text !== "string") {
    return text
  }
  if (text.length > 1) {
    return text.substr(0, 1).toUpperCase() + text.substr(1)
  } if (text.length === 1) {
    return text.toUpperCase()
  } else {
    return text
  }
}
