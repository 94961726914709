<template>
  <b-row>
    <b-col class="abschnitt auftraggeber mb-2">
      <b-row>
        <b-col class="title mb-2">
          <div>
            <strong>Auftraggeber</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="Unternehmen"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Unternehmen" vid="kunde.firmenname">
              <b-form-input trim v-model="form.kunde.firmenname" :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Branche"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Branche" vid="kunde.branche">
              <b-form-select v-model="form.kunde.branche" :options="branchenOptions"
                             :disabled="disabled"
                             :size="inputSize"
                             :state="$getValidationState(validationContext)" v-on:change="$emit('change', form)"
                             ref="branche" v-on:focusout.native="e => $refs.branche.$emit('blur', e)"></b-form-select>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            description="vertreten durch (Vorname und Name des Vertreters/der Vertreter)"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Vertreter"
                                vid="kunde.lieferadresse.nachname">
              <b-form-input trim v-model="form.kunde.lieferadresse.nachname"
                            :disabled="disabled"
                            :size="inputSize"
                            :state="$getValidationState(validationContext)"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            description="Registergericht (falls vorhanden)"
          >
            <b-form-input trim v-model="form.kunde.registergericht" v-on:change="$emit('change', form)"
                          :disabled="disabled"
                          :size="inputSize"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            description="Handelsregister-Nr. (falls vorhanden)"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Handelsregisternummer"
                                vid="kunde.lieferadresse.handelsregisternummer">
              <b-form-input trim v-model="form.kunde.lieferadresse.handelsregisternummer"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            description="Anschrift der Verbrauchsstelle: Straße"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Straße"
                                vid="kunde.lieferadresse.strasse">
              <b-form-input trim v-model="form.kunde.lieferadresse.strasse"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group
            description="Hausnr."
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Hausnr."
                                vid="kunde.lieferadresse.hausnummer">
              <b-form-input trim v-model="form.kunde.lieferadresse.hausnummer"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            description="PLZ"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Ort" vid="kunde.lieferadresse.plz">
              <b-form-input type="number" trim v-model="form.kunde.lieferadresse.plz"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Ort"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Ort" vid="kunde.lieferadresse.ort">
              <b-form-input trim v-model="form.kunde.lieferadresse.ort"
                            :disabled="disabled"
                            :size="inputSize"
                            :state="$getValidationState(validationContext)"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding-top: 1px;">
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            description="Rechnungsanschrift (falls von der Verbrauchsstelle abweichend): Unternehmen"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Unternehmen"
                                vid="kunde.rechnungsadresse.nachname">
              <b-form-input trim v-model="form.kunde.rechnungsadresse.nachname"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            description="Straße"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Hausnr."
                                vid="kunde.rechnungsadresse.strasse">
              <b-form-input trim v-model="form.kunde.rechnungsadresse.strasse"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            description="Hausnr."
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Hausnr."
                                vid="kunde.rechnungsadresse.hausnummer">
              <b-form-input trim v-model="form.kunde.rechnungsadresse.hausnummer"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="Telefon (freiwillige Angabe)">
            <b-row no-gutters>
              <b-col cols="3">
                <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: form.kunde.telefonnummer.vorwahl !== ''}" name="Land"
                                    vid="kunde.telefonnummer.land">
                  <b-form-input trim v-model="form.kunde.telefonnummer.land" placeholder="+49"
                                :disabled="disabled"
                                :size="inputSize"
                                :state="$getValidationState(validationContext)"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="numeric" name="Vorwahl"
                                    vid="kunde.telefonnummer.vorwahl">
                  <b-form-input trim v-model="form.kunde.telefonnummer.vorwahl" placeholder="Vorwahl"
                                :state="$getValidationState(validationContext)"
                                :disabled="disabled"
                                :size="inputSize"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
              <b-col cols="5">
                <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: form.kunde.telefonnummer.vorwahl !== '', numeric: true}" name="Rufnummer"
                                    vid="kunde.telefonnummer.rufnummer">
                  <b-form-input trim v-model="form.kunde.telefonnummer.rufnummer" placeholder="Rufnummer"
                                :state="$getValidationState(validationContext)"
                                :disabled="disabled"
                                :size="inputSize"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="E-Mail (freiwillige Angabe)"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="email" name="Email" vid="kunde.email">
              <b-form-input trim v-model="form.kunde.email" :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="PLZ"
          >
            <b-form-input trim v-model="form.kunde.rechnungsadresse.plz"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Ort"
          >
            <b-form-input trim v-model="form.kunde.rechnungsadresse.ort"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding-top: 1px;">
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :description="ucfirst(form.produkttyp) + 'zählernummer'"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Zählernummer" vid="zaehlernummer">
              <b-form-input type="number" v-model="form.zaehlernummer" trim
                            :disabled="disabled"
                            :size="inputSize"
                            :state="$getValidationState(validationContext)"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Marktlokation"
          >
            <b-form-input v-model="form.kunde.marktlokation" trim
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Verbrauch im Vorjahr"
          >

            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Verbrauch" vid="verbrauch">
                <b-form-input trim v-model="form.verbrauch" v-on:change="$emit('change', form)"
                              :disabled="disabled"
                              :size="inputSize"
                              :state="$getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr>
        </b-col>
      </b-row>
    </b-col>
  </b-row>

</template>

<script>
  import Vue from 'vue'
  import {FormGroupPlugin, FormInputPlugin, FormPlugin, FormRadioPlugin, InputGroupPlugin} from 'bootstrap-vue'
  import {EmptyFormData as DefaultFormData} from '../constants'
  import {ucfirst} from '../helpers'

  Vue.use(FormPlugin)
  Vue.use(FormGroupPlugin)
  Vue.use(FormInputPlugin)
  Vue.use(FormRadioPlugin)
  Vue.use(InputGroupPlugin)

  export default {
    name: 'AuftraggeberGK',
    components: {},
    model: {
      prop: 'form',
      event: 'change'
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    data() {
      return {
        branche: null
      }
    },
    computed: {
      branchen() {
        return this.$store.state.branchen.branchen
      },
      branchenOptions() {
        let empty = {'value': null, 'text': 'Bitte auswählen...'}
        let branchen = [empty]
        if (this.branchen) {
          branchen.push(...(this.branchen.map(b => {
            return {'value': b.externId, 'text': b.name}
          })))
        }
        return branchen
      }
    },
    methods: {
      ucfirst: text => ucfirst(text)
    }
  }
</script>

<style scoped>

</style>
