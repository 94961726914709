<template>
  <b-row>
    <b-col class="abschnitt auftragvollmacht mb-2">
      <b-row>
        <b-col class="title mb-2">
          <div>
            <strong>Auftrag und Vollmacht</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{ required: { allowFalse: false } }"
                              name="Kenntnisnahme der Widerrufsbelehrung" vid="widerrufsbelehrungZurKenntnis">
            <b-form-checkbox :value="true" :unchecked-value="false" class="mr-2 mb-2" style="display: inline-block"
                             :state="$getValidationState(validationContext)"
                             :disabled="disabled"
                             :size="inputSize"
                             ref="widerruf" v-on:focusout.native="e => $refs.widerruf.$emit('blur', e)"
                             v-model="form.widerrufsbelehrungZurKenntnis" v-on:change="$emit('change', form)">Die
              Widerrufsbelehrung auf Seite 2 habe ich zur Kenntnis genommen.
            </b-form-checkbox>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="Unterschriftsort"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Unterschriftsort" vid="unterschriftsort">
              <b-form-input trim name="unterschriftsort" v-model="form.unterschriftsort"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>

        </b-col>
        <b-col>
          <b-form-group
            description="Unterschriftsdatum"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Unterschriftsdatum" vid="unterschriftsdatum">
              <b-form-input type="date" v-model="form.unterschriftsdatum"
                            :disabled="disabled"
                            :size="inputSize"
                            :state="$getValidationState(validationContext)"
                            :max="unterschriftsdatumMax"
                            v-on:change="() => {$emit('change', form); $parent.$emit('neues-unterschriftsdatum', form.unterschriftsdatum)}"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>

        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  import Vue from 'vue'
  import {FormCheckboxPlugin, FormGroupPlugin, FormInputPlugin, FormPlugin} from 'bootstrap-vue'
  import {EmptyFormData as DefaultFormData} from '../constants'

  Vue.use(FormPlugin)
  Vue.use(FormCheckboxPlugin)
  Vue.use(FormGroupPlugin)
  Vue.use(FormInputPlugin)

  export default {
    name: 'AuftragUndVollmachtPK',
    components: {},
    model: {
      prop: 'form',
      event: 'change'
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    data() {
      return {
        tarif: 'A',
        tarifOptions: [
          {value: 'A', text: 'Tarif A'},
          {value: 'B', text: 'Tarif B'},
          {value: 'C', text: 'Tarif C'}
        ]
      }
    },
    computed: {
      unterschriftsdatumMax() {
        const datum = new Date()
        return datum.getFullYear() + '-' + (datum.getMonth() < 9 ? '0' : '') + (datum.getMonth()+1) + '-' + datum.getDate()
      }
    },
    methods: {
    }
  }
</script>

<style scoped>

</style>
