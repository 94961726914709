import { extend } from 'vee-validate';

const _calculateAge = (birthday, referenceDate) => {
  if (referenceDate === null) {
    referenceDate = new Date()
    referenceDate.setHours(0)
    referenceDate.setMinutes(0)
    referenceDate.setSeconds(0)
  }

  if (!(referenceDate instanceof Date)) {
    referenceDate = new Date(referenceDate)
  }

  if (!(birthday instanceof Date)) {
    birthday = new Date(birthday)
    birthday.setHours(0)
    birthday.setMinutes(0)
    birthday.setSeconds(0)
  }

  var age = referenceDate.getFullYear() - birthday.getFullYear();
  var m = referenceDate.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && referenceDate.getDate() < birthday.getDate())) {
    age = age - 1;
  }
  return age
}

export default function install() {
  extend("minAge", {
    params: ["min", "referenceDate"],
    validate: (value, { min, referenceDate }) => {
      return (_calculateAge(value, referenceDate) >= min)
    },
    message: "Des Alter muss mindestens {min} Jahre betragen."
  });

  extend("maxAge", {
    params: ["max", "referenceDate"],
    validate: (value, { max, referenceDate }) => {
      return (_calculateAge(value, referenceDate) <= max)
    },
    message: "Des Alter darf maximal {max} Jahre betragen."
  });
}
