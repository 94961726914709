<template>
  <b-row>
    <b-col class="abschnitt auftragvollmacht mb-2">
      <b-row>
        <b-col class="title mb-2">
          <div>
            <strong>Zahlungsangaben und Erteilung eines SEPA-Basis-Lastschriftmandates</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="Name, Vorname des Kontoinhabers"
          >
            <b-form-input trim v-model="form.kunde.bankdaten.kontoinhaber"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="BIC"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="BIC" vid="kunde.bankdaten.bic">
              <b-form-input trim v-model="form.kunde.bankdaten.bic"
                            :disabled="disabled"
                            :size="inputSize"
                            :state="$getValidationState(validationContext)"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="IBAN"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required|IBAN" name="IBAN" vid="kunde.bankdaten.iban">
              <b-form-input trim v-model="form.kunde.bankdaten.iban"
                            :disabled="disabled"
                            :size="inputSize"
                            :state="$getValidationState(validationContext)"
                          v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Name, Ort des Kreditinstituts"
          >
            <b-form-input trim v-model="form.kunde.bankdaten.kreditinstitut"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <strong>Anschrift des Kontoinhabers (falls abweichend vom Vertragspartner):</strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <b-form-group
            description="Straße"
          >
            <b-form-input trim v-model="form.kunde.bankdaten.adresseKontoinhaber.strasse"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group
            description="Hausnr."
          >
            <b-form-input trim v-model="form.kunde.bankdaten.adresseKontoinhaber.hausnummer"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            description="PLZ"
          >
            <b-form-input trim v-model="form.kunde.bankdaten.adresseKontoinhaber.plz"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            description="Ort"
          >
            <b-form-input trim v-model="form.kunde.bankdaten.adresseKontoinhaber.ort"
                          :disabled="disabled"
                          :size="inputSize"
                          v-on:change="$emit('change', form)"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  import Vue from 'vue'
  import {FormGroupPlugin, FormInputPlugin, FormPlugin} from 'bootstrap-vue'
  import {EmptyFormData as DefaultFormData} from '../constants'
  import { extend } from 'vee-validate';
  import { IbanValidator } from '../helpers'

  Vue.use(FormPlugin)
  Vue.use(FormGroupPlugin)
  Vue.use(FormInputPlugin)

  extend('IBAN', IbanValidator)

  export default {
    name: 'Zahlungsangaben',
    model: {
      prop: 'form',
      event: 'change'
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    data() {
      return {}
    }
  }
</script>

<style scoped>

</style>
