<template>
  <div class="auftragserfassung">
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="submitForm">
        <b-row class="mb-3">
          <b-col cols="2">
            <EnergieartSchalter v-model="auftragRequestForm.produkttyp" v-on:change="onChangedProdukttyp"></EnergieartSchalter>
          </b-col>
          <b-col cols="2">
            <KundentypSchalter v-model="auftragRequestForm.kundentyp" v-on:change="onChangedKundentyp"></KundentypSchalter>
          </b-col>
        </b-row>
        <b-row ref="erroralert" v-if="formError !== null">
          <b-col>
            <b-alert :show="formError !== null" variant="danger">
              {{ formError }}
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <component v-bind:is="formularComponent" v-model="auftragRequestForm"
                       v-on:neues-unterschriftsdatum="loadTarife"></component>
          </b-col>
        </b-row>
        <b-row style="padding-top: 1px;">
          <b-col>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Auftragsdateien v-model="auftragRequestForm"
                             v-on:file-upload-started="onFileUploadStarted"
                             v-on:file-upload-failed="onFileUploadFailed"
                             v-on:file-upload-reset="onFileUploadReset"
                             v-on:file-upload-done="onFileUploadDone"></Auftragsdateien>
          </b-col>
        </b-row>
        <b-row style="padding-top: 1px;">
          <b-col>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" variant="primary" :disabled="submitRunning">
              <span v-if="submitRunning === false">Auftrag abschicken</span>
              <b-spinner label="Loading" class="mx-2" v-if="submitRunning === true" small></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
  import Vue from 'vue'
  import KundentypSchalter from '../../../common/components/KundentypSchalter'
  import EnergieartSchalter from '../../../common/components/EnergieartSchalter'
  import {Dateityp, EmptyFormData, ExampleFormData, PsoErrorCodes} from '../../../common/constants'
  import AuftragRequest from '../../../common/model/request/AuftragRequest'
  import AuftragService from '../../../common/api/private/AuftragService'
  import Auftrag from '../../../common/model/domain/Auftrag'
  import VueScrollTo from 'vue-scrollto'
  import {ucfirst} from '../../../common/helpers'

  import StromPK from '../../../common/components/Auftragsformulare/StromPK'
  import StromGK from '../../../common/components/Auftragsformulare/StromGK'
  import GasPK from '../../../common/components/Auftragsformulare/GasPK'
  import GasGK from '../../../common/components/Auftragsformulare/GasGK'

  import Auftragsdateien from '../../../common/components/Auftragsdateien'

  import {ValidationObserver} from 'vee-validate'

  import {AlertPlugin, ButtonPlugin, SpinnerPlugin} from 'bootstrap-vue'

  Vue.use(ButtonPlugin)
  Vue.use(AlertPlugin)
  Vue.use(SpinnerPlugin)

  export default {
    name: 'AuftragErfassen',
    components: {
      KundentypSchalter,
      EnergieartSchalter,
      ValidationObserver,
      StromPK,
      StromGK,
      GasPK,
      GasGK,
      Auftragsdateien
    },
    provide: {
      inputSize: 'sm'
    },
    data() {
      return {
        formLoading: true,
        submitRunning: false,
        formError: null,
        fileUploadRunning: 0,
        fileUploadDone: false,
        files: {
          [Dateityp.Auftrag]: [],
          [Dateityp.Fairberaten]: [],
          [Dateityp.Sonstiges]: []
        },
        auftragRequestForm: process.env.VUE_APP_USE_EXAMPLE_DATA === '1' ? ExampleFormData : EmptyFormData
      }
    },
    computed: {
      formularComponent() {
        return ucfirst(this.auftragRequestForm.produkttyp) + this.auftragRequestForm.kundentyp.toUpperCase()
      },
      submitEnabled() {
        return this.fileUploadDone && !this.submitRunning
      }
    },
    methods: {
      getTarife(requestData) {
        this.$store.dispatch('tarife/getTarife', requestData)
      },
      getVorversorger() {
        this.$store.dispatch('vorversorger/getVorversorger')
      },
      getBranchen() {
        this.$store.dispatch('branchen/getBranchen')
      },
      saveAuftrag() {
        let produkt = this.$store.state.tarife.tarife.filter(t => t.tarifId === this.auftragRequestForm.tarifId)
        AuftragService.create(new AuftragRequest(this.auftragRequestForm, produkt[0], this.files))
          .then((response) => {
            let auftrag = new Auftrag(response.data)
            this.$nextTick(() => {
              this.auftragRequestForm = process.env.VUE_APP_USE_EXAMPLE_DATA === '1' ? ExampleFormData : EmptyFormData
              this.$router.push({name: 'auftragerfasst', params: {auftragsnummer: auftrag.auftragsnummer}})
            })
          })
          .catch((error) => {
            let data = error.response.data
            if (data.psoErrorCode === PsoErrorCodes.InvalidFormat) {
              let errors = {}
              errors[data.psoErrorFieldName] = [data.psoErrorDetails]
              this.$refs.observer.setErrors(errors)
              this.formError = `Fehler in ${data.psoErrorFieldName}: ${data.psoErrorDetails}`
              this.$nextTick(() => {
                this.scrollToFirstInvalidFieldOrTop()
              })
            } else {
              this.formError = data.psoErrorDetails
              this.$nextTick(() => {
                this.scrollToFirstInvalidFieldOrTop()
              })
            }
          })
        .finally(() => {
          this.submitRunning = false
        })
      },
      submitForm() {
        this.submitRunning = true
        this.formError = null
        this.$refs.observer.validate().then(success => {
          if (success) {
            this.saveAuftrag()
          } else {
            this.submitRunning = false
            this.scrollToFirstInvalidFieldOrTop()
          }
        })
      },
      loadData() {
        this.loadTarife(this.auftragRequestForm.unterschriftsdatum)
        this.getVorversorger()
        this.getBranchen()
      },
      loadTarife(unterschriftsdatum) {
        if (unterschriftsdatum === null) {
          return
        }
        this.getTarife({
          stichtag: unterschriftsdatum,
          kundentyp: this.auftragRequestForm.kundentyp,
          produkttyp: this.auftragRequestForm.produkttyp
        })
      },
      scrollToFirstInvalidFieldOrTop() {
        let firstInvalidField = this.$el.querySelector('input.is-invalid, select.is-invalid')
        if (firstInvalidField !== null) {
          VueScrollTo.scrollTo(firstInvalidField)
          firstInvalidField.focus()
        } else {
          VueScrollTo.scrollTo(this.$refs.erroralert)
        }
      },
      onChangedProdukttyp() {
        this.loadData()
      },
      onChangedKundentyp() {
        this.loadData()
      },
      onFileUploadStarted() {
        this.fileUploadRunning++
        this.fileUploadDone = false
      },
      onFileUploadFailed() {
        this.fileUploadRunning--
      },
      onFileUploadReset(dateityp) {
        this.files[dateityp] = []
      },
      onFileUploadDone({dateityp, content}) {
        this.fileUploadRunning--
        this.files[dateityp].push(content)
        if (this.fileUploadRunning === 0) {
          this.fileUploadDone = true
        }
      }
    },
    mounted() {
      if (this.$route.params.produkttyp) {
        this.auftragRequestForm.produkttyp = this.$route.params.produkttyp
      }
      if (this.$route.params.kundentyp) {
        this.auftragRequestForm.kundentyp = this.$route.params.kundentyp
      }
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
