import Adresse from './Adresse'
import Bankdaten from './Bankdaten'
import Kontaktnummer from './Kontaktnummer'

class Kunde {
  /**
   * @param {{
   * email:?String,
   * geburtsdatum:?Date,
   * lieferadresse:{ anrede:String, vorname:String, nachname:String, strasse:String, hausnummer:String, hausnummerZusatz:?String, plz:String, ort:String },
   * rechnungsadresse:?{ anrede:String, vorname:String, nachname:String, strasse:String, hausnummer:String, hausnummerZusatz:?String, plz:String, ort:String },
   * telefonnummer:{ land:String, vorwahl:String, rufnummer:String },
   * faxnummer:?{ land:String, vorwahl:String, rufnummer:String },
   * bankdaten:?{
   *      kontoinhaber:String,
   *      iban:String,
   *      bic:String,
   *      kreditinstitut:String,
   *      adresseKontoinhaber:?{
   *          anrede:String,
   *          vorname:String,
   *          nachname:String,
   *          strasse:String,
   *          hausnummer:String,
   *          hausnummerZusatz:?String, plz:String, ort:String } },
   * promotionErlaubtEmail:Boolean,
   * promotionErlaubtTelefonSms:Boolean,
   * promotionHighlightsErlaubt:Boolean
   * firmenname:?String,
   * registergericht:?String,
   * handelsregisternummer:?String,
   * branche:?String }} data
   */
  constructor(data) {
    this.email = data.email
    this.geburtsdatum = data.geburtsdatum instanceof Date ? data.geburtsdatum : new Date(data.geburtsdatum)
    this.lieferadresse = data.lieferadresse
    this.rechnungsadresse = new Adresse(data.rechnungsadresse)
    this.telefonnummer = new Kontaktnummer(data.telefonnummer)
    this.faxnummer = new Kontaktnummer(data.faxnummer)
    this.bankdaten = new Bankdaten(data.bankdaten)
    this.promotionErlaubtEmail = data.promotionErlaubtEmail
    this.promotionErlaubtTelefonSms = data.promotionErlaubtTelefonSms
    this.promotionHighlightsErlaubt = data.promotionHighlightsErlaubt
    this.firmenname = data.firmenname
    this.registergericht = data.registergericht
    this.handelsregisternummer = data.handelsregisternummer
    this.branche = data.branche
  }
}
export default Kunde
