<template>
  <b-row>
    <b-col class="abschnitt auftraggeber mb-2">
      <b-row>
        <b-col class="title mb-2">
          <div>
            <strong>Auftraggeber</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Anrede" vid="kunde.lieferadresse.anrede">
            <b-form-group
              description="Anrede"
              :state="$getValidationState(validationContext)"
            >
              <b-form-radio :value="Anreden.Frau" inline v-model="form.kunde.lieferadresse.anrede"
                            v-on:change="$emit('change', form)"
                            ref="anrede_frau"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:focusout.native="e => $refs.anrede_frau.$emit('blur', e)">Frau
              </b-form-radio>
              <b-form-radio :value="Anreden.Herr" inline v-model="form.kunde.lieferadresse.anrede"
                            ref="anrede_herr"
                            v-on:change="$emit('change', form)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:focusout.native="e => $refs.anrede_herr.$emit('blur', e)">Herr
              </b-form-radio>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="3">
          <b-form-group
            description="Name"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Nachname" vid="kunde.lieferadresse.nachname">
              <b-form-input trim v-model="form.kunde.lieferadresse.nachname"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Vorname"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Vorname" vid="kunde.lieferadresse.vorname">
              <b-form-input trim v-model="form.kunde.lieferadresse.vorname"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Geburtsdatum"
          >
            <ValidationProvider
              :disabled="disabled"
              v-slot="validationContext"
              :rules="{required: true, minAge: {min: 18, referenceDate: form.unterschriftsdatum}, maxAge: {max: 99, referenceDate: form.unterschriftsdatum}}"
              name="Geburtsdatum" vid="kunde.geburtsdatum">
              <b-form-input type="date" v-model="form.kunde.geburtsdatum"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            :min="geburtsdatumMin"
                            :max="geburtsdatumMax"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            description="Anschrift der Lieferstelle: Straße"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Straße" vid="kunde.lieferadresse.strasse">
              <b-form-input trim v-model="form.kunde.lieferadresse.strasse"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group
            description="Hausnr."
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Hausnr." vid="kunde.lieferadresse.hausnummer">
              <b-form-input trim v-model="form.kunde.lieferadresse.hausnummer"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            description="PLZ"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="PLZ" vid="kunde.lieferadresse.plz">
              <b-form-input type="number" trim v-model="form.kunde.lieferadresse.plz"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="Ort"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Ort" vid="kunde.lieferadresse.ort">
              <b-form-input trim v-model="form.kunde.lieferadresse.ort" :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="Telefon (freiwillige Angabe)"
          >
            <b-row no-gutters>
              <b-col cols="3">
                <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: form.kunde.telefonnummer.vorwahl !== ''}" name="Land" vid="kunde.telefonnummer.land">
                  <b-form-input trim v-model="form.kunde.telefonnummer.land" placeholder="+49"
                                :state="$getValidationState(validationContext)"
                                :disabled="disabled"
                                :size="inputSize"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="numeric" name="Vorwahl" vid="kunde.telefonnummer.vorwahl">
                  <b-form-input trim v-model="form.kunde.telefonnummer.vorwahl" placeholder="Vorwahl"
                                :state="$getValidationState(validationContext)"
                                :disabled="disabled"
                                :size="inputSize"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
              <b-col cols="5">
                <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: form.kunde.telefonnummer.vorwahl !== '', numeric: true}" name="Rufnummer" vid="kunde.telefonnummer.rufnummer">
                  <b-form-input trim v-model="form.kunde.telefonnummer.rufnummer" placeholder="Rufnummer"
                                :state="$getValidationState(validationContext)"
                                :disabled="disabled"
                                :size="inputSize"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
            </b-row>

          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            description="E-Mail (freiwillige Angabe)"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="email" name="E-Mail" vid="kunde.email">
              <b-form-input trim v-model="form.kunde.email" :state="form.kunde.email !== '' ? $getValidationState(validationContext) : null"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="ucfirst(form.produkttyp) + 'zählernummer'"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required|numeric" name="Zählernummer" vid="zaehlernummer">
              <b-form-input trim v-model="form.zaehlernummer" :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="ucfirst(form.produkttyp) + 'verbrauch im Vorjahr'"
          >
            <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required|numeric" name="Verbrauch" vid="verbrauch">
                <b-form-input trim v-model="form.verbrauch" :state="$getValidationState(validationContext)"
                              :disabled="disabled"
                              :size="inputSize"
                              v-on:change="$emit('change', form)"></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <strong>Rechnungsanschrift</strong> (<b-form-checkbox inline v-model="rechnungsanschriftWeichtAb" class="mr-0">weicht von der oben genannten Lieferstelle ab</b-form-checkbox>):
        </b-col>
      </b-row>
      <b-collapse id="collapse-rechnungsanschrift" v-model="rechnungsanschriftWeichtAb">
        <b-row>
          <b-col cols="2">
            <b-form-group
              description="Anrede"
            >
              <b-form-radio :value="Anreden.Frau" inline v-model="form.kunde.rechnungsadresse.anrede"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)">Frau
              </b-form-radio>
              <b-form-radio :value="Anreden.Herr" inline v-model="form.kunde.rechnungsadresse.anrede"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)">Herr
              </b-form-radio>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              description="Name"
            >
              <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: rechnungsanschriftWeichtAb === true}" name="Name" vid="kunde.rechnungsadresse.nachname">
                <b-form-input trim v-model="form.kunde.rechnungsadresse.nachname"
                              :state="$getValidationState(validationContext)"
                              :disabled="disabled"
                              :size="inputSize"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              description="Vorname"
            >
              <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: rechnungsanschriftWeichtAb === true}" name="Vorname" vid="kunde.rechnungsadresse.vorname">
              <b-form-input trim v-model="form.kunde.rechnungsadresse.vorname"
                            :state="$getValidationState(validationContext)"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              description="Rechnungsanschrift: Straße"
            >
              <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: rechnungsanschriftWeichtAb === true}" name="Straße" vid="kunde.rechnungsadresse.strasse">
                <b-form-input trim v-model="form.kunde.rechnungsadresse.strasse"
                              :state="$getValidationState(validationContext)"
                              :disabled="disabled"
                              :size="inputSize"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              description="Hausnr."
            >
              <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: rechnungsanschriftWeichtAb === true}" name="Hausnr." vid="kunde.rechnungsadresse.hausnummer">
                <b-form-input trim v-model="form.kunde.rechnungsadresse.hausnummer"
                              :state="$getValidationState(validationContext)"
                              :disabled="disabled"
                              :size="inputSize"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              description="PLZ"
            >
              <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: rechnungsanschriftWeichtAb === true}" name="PLZ" vid="kunde.rechnungsadresse.plz">
                <b-form-input type="number" trim v-model="form.kunde.rechnungsadresse.plz"
                              :state="$getValidationState(validationContext)"
                              :disabled="disabled"
                              :size="inputSize"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              description="Ort"
            >
              <ValidationProvider :disabled="disabled" v-slot="validationContext" :rules="{required: rechnungsanschriftWeichtAb === true}" name="Ort" vid="kunde.rechnungsadresse.ort">
                <b-form-input trim v-model="form.kunde.rechnungsadresse.ort" :state="$getValidationState(validationContext)"
                              :disabled="disabled"
                              :size="inputSize"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
    </b-col>
  </b-row>

</template>

<script>
  import Vue from 'vue'
  import {FormGroupPlugin, FormInputPlugin, FormPlugin, FormCheckboxPlugin, FormRadioPlugin, InputGroupPlugin, CollapsePlugin } from 'bootstrap-vue'
  import {EmptyFormData as DefaultFormData, Anreden} from '../constants'
  import {ucfirst, installAgeValidator} from '../helpers'

  Vue.use(FormPlugin)
  Vue.use(FormGroupPlugin)
  Vue.use(FormInputPlugin)
  Vue.use(FormRadioPlugin)
  Vue.use(InputGroupPlugin)
  Vue.use(CollapsePlugin)
  Vue.use(FormCheckboxPlugin)

  installAgeValidator()

  export default {
    name: 'AuftraggeberPK',
    model: {
      prop: 'form',
      event: 'change'
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    data() {
      return {
        Anreden: Anreden,
        rechnungsanschriftWeichtAb: false
      }
    },
    computed: {
      geburtsdatumMin() {
        let min = ''
        if (this.form.unterschriftsdatum !== null) {
          let datum = new Date(this.form.unterschriftsdatum)
          datum.setDate(datum.getDate() + 1)
          datum.setFullYear(datum.getFullYear() - 100)
          min = datum.getFullYear() + '-' + (datum.getMonth() < 9 ? '0' : '') + (datum.getMonth()+1) + '-' + datum.getDate()
        }
        return min
      },
      geburtsdatumMax() {
        let max = ''
        if (this.form.unterschriftsdatum !== null) {
          let datum = new Date(this.form.unterschriftsdatum)
          datum.setFullYear(datum.getFullYear() - 18)
          max = datum.getFullYear() + '-' + (datum.getMonth() < 9 ? '0' : '') + (datum.getMonth()+1) + '-' + datum.getDate()
        }
        return max
      }
    },
    methods: {
      ucfirst: text => ucfirst(text)
    }
  }
</script>

<style scoped>

</style>
