<template>
  <div class="auftragsformular">
    <Auftraggeber v-model="form" :disabled="disabled" v-on:change="$emit('change', form)"></Auftraggeber>
    <LieferbeginnUndTarif v-model="form" :disabled="disabled" v-on:change="$emit('change', form)"></LieferbeginnUndTarif>
    <AuftragUndVollmacht v-model="form" :disabled="disabled" v-on:change="$emit('change', form)"></AuftragUndVollmacht>
    <ProduktUndTarifinformationen v-model="form" :disabled="disabled" v-on:change="$emit('change', form)"></ProduktUndTarifinformationen>
    <Zahlungsangaben v-model="form" :disabled="disabled" v-on:change="$emit('change', form)"></Zahlungsangaben>
  </div>
</template>

<script>
  import Auftraggeber from '../AuftraggeberPK'
  import LieferbeginnUndTarif from '../LieferbeginnUndTarif'
  import AuftragUndVollmacht from '../AuftragUndVollmachtPK'
  import ProduktUndTarifinformationen from '../ProduktUndTarifinformationen'
  import Zahlungsangaben from '../ZahlungsangabenPK'
  import {EmptyFormData as DefaultFormData} from '../../constants'

  export default {
    name: 'GasPK',
    model: {
      prop: 'form',
      event: 'change'
    },
    components: {
      Auftraggeber,
      LieferbeginnUndTarif,
      AuftragUndVollmacht,
      ProduktUndTarifinformationen,
      Zahlungsangaben
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
      }
    }
  }
</script>

<style scoped>

</style>
