import '../../model/request'
import * as axios from 'axios'
export default {
  /** @param {Number} id */
  get(id) {
    return axios.get(`energie/auftrag/${id}`)
  },
  getCsvList() {
    return axios.get(`energie/auftrag/csv`, {responseType: 'blob'})
  },
  getXlsList() {
    return axios.get(`energie/auftrag/xls`)
  },
  /** @param {AuftragRequest} auftragRequest */
  create(auftragRequest) {
    return axios.post(`energie/auftrag`, auftragRequest.toBodyParameter())
  }
}
