<template>
  <b-row>
    <b-col class="abschnitt lieferbeginn mb-2">
      <b-row>
        <b-col class="title mb-2">
          <div>
            <strong>Lieferbeginn und Tarifwahl</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1">
          <strong>Grund:</strong>
        </b-col>
        <b-col cols="1">
          <b-form-radio name="aktionscode" vid="aktionscode" :value="Wechselgrund.Umzug" class="mr-2 mb-2"
                        style="display: inline-block"
                        :disabled="disabled"
                        :size="inputSize"
                        v-model="form.wechselgrund" v-on:change="$emit('change', form)">Einzug
          </b-form-radio>
        </b-col>
        <b-col cols="2">
          <b-form-radio name="aktionscode" vid="aktionscode" :value="Wechselgrund.Neueinzug" class="mx-2 mb-2"
                        :disabled="disabled"
                        :size="inputSize"
                        style="display: inline-block" v-model="form.wechselgrund" v-on:change="$emit('change', form)">
            Erstbezug/Neubau
          </b-form-radio>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="4">
              Datum der Schlüsselübernahme:
            </b-col>
            <b-col>
              <ValidationProvider :disabled="disabled" v-slot="validationContext"
                                  :rules="form.wechselgrund === Wechselgrund.Neueinzug ? 'required' : ''"
                                  name="Lieferbeginn"
                                  vid="lieferbeginn">
                <b-form-input type="date" v-model="form.lieferbeginn" v-on:change="$emit('change', form)"
                              :disabled="disabled || form.wechselgrund !== Wechselgrund.Neueinzug"
                              :size="inputSize"
                              :state="form.wechselgrund === Wechselgrund.Neueinzug ? $getValidationState(validationContext) : null"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2">
          <b-form-radio name="aktionscode" vid="aktionscode" :value="Wechselgrund.Lieferantenwechsel"
                        :disabled="disabled"
                        :size="inputSize"
                        class="ml-2 mb-2"
                        style="display: inline-block" v-model="form.wechselgrund" v-on:change="$emit('change', form)">
            Anbieterwechsel (neuer Lieferant)
          </b-form-radio>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <strong>Nur bei Anbieterwechsel ausfüllen:</strong>
        </b-col>
        <b-col>
          <b-row>
            <b-col v-if="!disabled">
              <b-form-group
                :description="'bisheriger ' + ucfirst(form.produkttyp) + 'lieferant'"
              >
                <ValidationProvider :disabled="disabled" v-slot="validationContext"
                                    :rules="form.wechselgrund === Wechselgrund.Lieferantenwechsel ? 'required' : ''"
                                    name="Vorversorger">
                  <b-form-select v-model="form.vorversorger" :options="vorversorgerOptions"
                                 :state="$getValidationState(validationContext)"
                                 :disabled="disabled"
                                 :size="inputSize"
                                 ref="vorversorger" v-on:focusout.native="e => $refs.vorversorger.$emit('blur', e)"
                                 v-on:change="$emit('change', form)"></b-form-select>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col v-if="disabled">
              {{vorversorgerName}}
            </b-col>
            <b-col>
              <b-form-group
                :description="'Kundennummer beim bisherigen ' + ucfirst(form.produkttyp) + 'lieferanten'"
              >
                <ValidationProvider :disabled="disabled" v-slot="validationContext"
                                    :rules="form.wechselgrund === Wechselgrund.Lieferantenwechsel ? 'required' : ''"
                                    name="Kundennummer">
                  <b-form-input trim v-model="form.vorversorgerKundennummer"
                                :state="$getValidationState(validationContext)"
                                :disabled="disabled"
                                :size="inputSize"
                                v-on:change="$emit('change', form)"></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>Haben Sie Ihren Vertrag bei Ihrem derzeitigen Lieferanten schon gekündigt?</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1">
              <b-form-radio name="kuendigung" :value="Kuendiger.Selbstgekuendigt" v-model="form.kuendiger"
                            :disabled="disabled"
                            :size="inputSize"
                            v-on:change="$emit('change', form)">Ja, zum
              </b-form-radio>
            </b-col>
            <b-col>
              <ValidationProvider :disabled="disabled" v-slot="validationContext"
                                  :rules="form.kuendiger === Kuendiger.Selbstgekuendigt ? 'required' : ''"
                                  name="Kündigungsdatum">
                <b-form-input type="date"
                              :disabled="disabled || form.kuendiger !== Kuendiger.Selbstgekuendigt"
                              :size="inputSize"
                              :state="form.kuendiger === Kuendiger.Selbstgekuendigt ? $getValidationState(validationContext) : null"
                              v-model="form.vorversorgerKuendigungsdatum"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-col>
            <b-col cols="2">
              <b-form-radio name="kuendigung" :value="Kuendiger.NeinNaechstmoeglich"
                            :disabled="disabled"
                            :size="inputSize"
                            v-model="form.kuendiger"
                            v-on:change="$emit('change', {...form, ...{vorversorgerKuendigungsdatum: ''}})">Nein,
                Vattenfall soll zum nächstmöglichen Zeitpunkt
                kündigen
              </b-form-radio>
            </b-col>
            <b-col cols="2">
              <b-form-radio name="kuendigung" :value="Kuendiger.NeinTermin"
                            :disabled="disabled"
                            :size="inputSize"
                            v-model="form.kuendiger"
                            v-on:change="$emit('change', form)">Nein, Vattenfall soll kündigen zum:
              </b-form-radio>
            </b-col>
            <b-col>
              <ValidationProvider :disabled="disabled" v-slot="validationContext"
                                  :rules="form.kuendiger === Kuendiger.NeinTermin ? 'required' : ''"
                                  name="Kündigungsdatum">
                <b-form-input type="date"
                              :disabled="disabled || form.kuendiger !== Kuendiger.NeinTermin"
                              :size="inputSize"
                              :state="form.kuendiger === Kuendiger.NeinTermin ? $getValidationState(validationContext) : null"
                              v-model="form.vorversorgerKuendigungsdatum"
                              v-on:change="$emit('change', form)"></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="padding-top: 1px;">
        <b-col>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-row>
            <b-col :v-if="!disabled">
              <strong>Ich beauftrage die Vattenfall Europe Sales GmbH mit der {{ ucfirst(form.produkttyp) }}lieferung zu diesen
                Konditionen:</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              Mein Tarif:
            </b-col>
            <b-col :v-if="!disabled">
              <ValidationProvider :disabled="disabled" v-slot="validationContext" rules="required" name="Tarif">
                <b-form-select v-model="form.tarifId" :options="tarifOptions"
                               :state="$getValidationState(validationContext)"
                               :disabled="disabled"
                               :size="inputSize"
                               ref="tarif" v-on:focusout.native="e => $refs.tarif.$emit('blur', e)"
                               v-on:change="$emit('change', form)"></b-form-select>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row :v-if="disabled">
            <b-col cols="2">
              Grundpreis:
            </b-col>
            <b-col>
              <p>Preise, Boni und Vertragslaufzeit werden nicht digitalisiert. Achte auf die Auswahl des korrekten
                Tarifs.</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row :v-if="!disabled">
            <b-col>
              <strong>Mein Bonus:</strong>
            </b-col>
          </b-row>
          <b-row :v-if="!disabled">
            <b-col>
              <p>Preise, Boni und Vertragslaufzeit werden nicht digitalisiert. Achte auf die Auswahl des korrekten
                Tarifs.</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  import Vue from 'vue'
  import {
    FormGroupPlugin,
    FormInputPlugin,
    FormPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    InputGroupPlugin
  } from 'bootstrap-vue'
  import {
    EmptyFormData as DefaultFormData,
    Kuendiger,
    Wechselgrund
  } from '../constants'
  import {ucfirst} from '../helpers'
  // import { ValidationProvider } from 'vee-validate';

  Vue.use(FormPlugin)
  Vue.use(FormGroupPlugin)
  Vue.use(FormInputPlugin)
  Vue.use(FormRadioPlugin)
  Vue.use(FormSelectPlugin)
  Vue.use(InputGroupPlugin)

  export default {
    name: 'LieferbeginnUndTarif',
    components: {
      // ValidationProvider
    },
    model: {
      prop: 'form',
      event: 'change'
    },
    props: {
      form: {
        type: Object,
        default: function () {
          return DefaultFormData
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    inject: ['inputSize'],
    data() {
      return {
        Wechselgrund: Wechselgrund,
        Kuendiger: Kuendiger
      }
    },
    computed: {
      tarife() {
        return this.$store.state.tarife.tarife
      },
      vorversorger() {
        return this.$store.state.vorversorger.vorversorger
      },
      vorversorgerName() {
        let vorversorger = (this.vorversorger.filter(v => v.bdewNr === this.form.vorversorger))[0]
        return vorversorger ? vorversorger.name : ''
      },
      tarifOptions() {
        let emptyText = 'Bitte auswählen...'
        if (this.form.unterschriftsdatum === null) {
          emptyText = 'Bitte Unterschriftsdatum angeben...'
        }
        let empty = {'value': null, 'text': emptyText}
        let tarife = [empty]
        if (this.tarife) {
          tarife.push(...(this.tarife.map(t => {
            return {'value': t.tarifId, 'text': t.name}
          })))
        }
        return tarife
      },
      vorversorgerOptions() {
        let empty = {'value': null, 'text': 'Bitte auswählen...'}
        let vorversorger = [empty]
        vorversorger.push(...(this.vorversorger.filter(v => v.produkttyp ? v.produkttyp === ucfirst(this.form.produkttyp) : true).map(x => {
          return {'value': x.bdewNr, 'text': x.name}
        })))
        return vorversorger
      }
    },
    methods: {
      ucfirst: text => ucfirst(text)
    }
  }
</script>

<style scoped>

</style>
